import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Space, Text } from '@mantine/core';
import { IconAlertTriangle, IconRefreshAlert } from '@tabler/icons-react';

const TokenExpired = () => {
	return (
		<Flex h='100%' justify='center' align='center' direction='column' gap='55px'>
			<Flex justify='center' align='center' direction='column'>
				<IconAlertTriangle size='120' color='red' />
				<Space h='md' />
				<Text fw={700} fz={25}>
					Token Expired!
				</Text>
				<Text c='dimmed'>Please try again..!!</Text>
			</Flex>
			<Link to='/login' style={{ textDecoration: 'none' }}>
				<Button
					fullWidth
					radius='md'
					size='md'
					sx={{
						fontSize: '14px'
					}}
					leftIcon={<IconRefreshAlert />}
					variant='gradient'
				>
					Try Again
				</Button>
			</Link>
		</Flex>
	);
};

export default TokenExpired;
