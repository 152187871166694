import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, LoadingOverlay, Paper, Space, Text, TextInput, rem, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

import '../../login/styles/login.scss';

interface FormValues {
	email: string;
}

const ForgotPassword: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [checkedEmail, setCheckedEmail] = useState(false);
	const form = useForm({
		initialValues: {
			email: ''
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Please Enter email')
		}
	});

	/* API call for forgot password */
	const handleSubmit = (values: FormValues) => {
		setLoading(true);
		httpService
			.post(API_CONFIG.path.forgotPassword, values)
			.then((res) => {
				setCheckedEmail(!checkedEmail);
				setLoading(false);
			})
			.catch((err) => {
				console.error('Error', err);
				setLoading(false);
				notifications.show({
					message: err.response.data.message,
					color: 'red'
				});
			});
	};

	return (
		<Box className='auth-container' pos={'relative'}>
			{loading && (
				<LoadingOverlay
					loaderProps={{
						size: 'xl'
					}}
					visible={loading}
					overlayBlur={2}
				/>
			)}

			{!checkedEmail && (
				<Box w='100%'>
					<Text size={27} ta='center' fw={700}>
						Forgot Password?
					</Text>

					<Text fz='sm' ta='center' color='#868e96'>
						Enter your email to get a reset link
					</Text>

					<Space h='sm' />
					<form onSubmit={form.onSubmit(handleSubmit)}>
						<TextInput
							styles={{
								label: { fontSize: '14px' },
								error: { fontSize: '12px' }
							}}
							withAsterisk
							radius='md'
							size='md'
							placeholder='Email'
							sx={{ marginTop: '30px' }}
							{...form.getInputProps('email')}
						/>

						<Space h='xl' />
						<Button
							fullWidth
							radius='md'
							size='md'
							sx={{
								marginBottom: 20,
								fontSize: '14px'
							}}
							variant='gradient'
							type='submit'
						>
							Request New Password
						</Button>

						<Link to='/login' style={{ textDecoration: 'none' }}>
							<Flex justify='end' align='center'>
								<IconArrowLeft
									size={rem(18)}
									stroke={2}
									style={{
										marginRight: '5px',
										color: '#228be6'
									}}
								/>

								<Text fz='md' ta='right' color='blue' fw={500}>
									Back to Login
								</Text>
							</Flex>
						</Link>
					</form>
				</Box>
			)}

			{checkedEmail && (
				<Box w='100%'>
					<Paper
						shadow='sm'
						p='xl'
						maw={500}
						radius='md'
						sx={{
							width: '100%',
							maxWidth: '420px',
							backgroundColor: '#fff',
							borderLeft: '4px solid #4C6EF5'
						}}
					>
						<Text ta='left' fz='xl' fw={600} sx={{ marginBottom: '10px' }}>
							We have sent you an email for Reset Password.
						</Text>
						<Text ta='left' color='dimmed' fz='sm'>
							Please check your promotional or spam folders just in case.
						</Text>
					</Paper>
					<Link to='/login' style={{ textDecoration: 'none' }}>
						<Flex justify='flex-end' mt={20}>
							<IconArrowLeft
								size={rem(18)}
								stroke={2}
								style={{
									marginRight: '5px',
									color: '#228be6'
								}}
							/>

							<Text fz='md' ta='right' color='blue' fw={500}>
								Back to Login
							</Text>
						</Flex>
					</Link>
				</Box>
			)}
		</Box>
	);
};

export default ForgotPassword;
