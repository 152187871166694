import { lazy } from 'react';

const Dashboard = lazy(() => import('features/dashboard/container/dashboard'));
const User = lazy(() => import('features/User/component/user'));
const ViewUser = lazy(() => import('features/User/component/viewUser'));
const Timesheet = lazy(() => import('features/timesheet/component/timesheet'));
const LeaveList = lazy(() => import('features/leave/component/leaveList'));
const WfhList = lazy(() => import('features/wfh/component/wfhList'));
const HolidayList = lazy(() => import('features/holiday/component/holidayList'));
const Request = lazy(() => import('features/request/container/request'));
const Projects = lazy(() => import('features/project/component/project'));
const ProjectDetails = lazy(() => import('features/project/component/projectAddForm'));
const Calendar = lazy(() => import('features/calendar/component/calendar'));
const Report = lazy(() => import('features/report/component/report'));
const UpcomingLeave = lazy(() => import('features/upcoming/component/leaves'));

export {
	Dashboard,
	User,
	ViewUser,
	Timesheet,
	LeaveList,
	WfhList,
	HolidayList,
	Request,
	Projects,
	ProjectDetails,
	Calendar,
	Report,
	UpcomingLeave
};
