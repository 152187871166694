import React, { useState, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { IconLogout } from '@tabler/icons-react';
import {
	AppShell,
	Navbar,
	MediaQuery,
	Burger,
	useMantineTheme,
	ScrollArea,
	Space,
	Flex,
	Box,
	Menu,
	Text,
	rem
} from '@mantine/core';

import authService from 'shared/services/auth.service';
import { createAction } from 'shared/util/utility';

import * as actionTypes from 'store/action-types';
import { NavLinks } from '../component/NavLinks';
import { Brand } from '../component/brand';
import '../styles/layout.scss';

const Layout: React.FC<PropsWithChildren> = (props) => {
	const userData = authService.getAuthData();

	const theme = useMantineTheme();
	const [isOpened, setIsOpened] = useState(false);

	const dispatch = useDispatch();

	return (
		<AppShell
			padding={0}
			navbarOffsetBreakpoint='sm'
			asideOffsetBreakpoint='sm'
			navbar={
				<Navbar
					sx={{ boxShadow: '-8px 8px 10px #888888' }}
					p='xs'
					pl={0}
					pr={0}
					pt={0}
					hiddenBreakpoint='sm'
					hidden={!isOpened}
					width={{ sm: 200, lg: 250 }}
				>
					<Navbar.Section>
						<Brand />
					</Navbar.Section>
					<Navbar.Section grow mt='md' component={ScrollArea} pl={15} pr={15}>
						<NavLinks />
					</Navbar.Section>
				</Navbar>
			}
		>
			<ScrollArea sx={{ backgroundColor: '#efefef80', height: '100%' }}>
				<Box
					pt={10}
					pl={0}
					pr={0}
					pb={0}
					bg={'white'}
					sx={{ position: 'fixed', width: 'calc(100% - 250px)', zIndex: 10 }}
				>
					<Flex
						align={'center'}
						justify='space-between'
						sx={(theme) => ({
							borderBottom: `${rem(1)} solid ${
								theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
							}`
						})}
					>
						<MediaQuery largerThan='sm' styles={{ display: 'none' }}>
							<Burger
								opened={isOpened}
								onClick={() => setIsOpened((o) => !o)}
								size='sm'
								color={theme.colors.gray[6]}
								mr='xl'
							/>
						</MediaQuery>
						<Space w='md' />
						<Flex align='center' mr={10} h={44}>
							<Menu width={200} shadow='md' withArrow position='bottom-end' trigger='hover'>
								<Menu.Target>
									<Flex align='center' pb={10} mr={15}>
										<Text fw='700' c={'#B5B5C3'} mr='sm'>
											{userData.realName}
										</Text>
										<Box>
											<img
												src={userData.avatar}
												alt={`${userData.realName}'s Profile`}
												style={{ borderRadius: '0.5rem', width: 36, height: 36 }}
											/>
										</Box>
									</Flex>
								</Menu.Target>

								<Menu.Dropdown>
									<Menu.Item
										color={theme.colors.blue[5]}
										icon={<IconLogout />}
										onClick={() => dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS))}
									>
										Sign out
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</Flex>
					</Flex>
				</Box>
				{props.children}
			</ScrollArea>
		</AppShell>
	);
};

export default Layout;
