import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Button, LoadingOverlay, PasswordInput, PasswordInputProps, Space, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import RESET_PASSWORD_FIELDS from 'features/login/constants/constants';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

import '../../login/styles/login.scss';

interface FormValues {
	password: string;
	confirmPassword: string;
}

const ResetPassword: React.FC = ({ ...others }: PasswordInputProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const token = location.pathname.split('/reset-password/')[1];

	const form = useForm({
		initialValues: {
			password: '',
			confirmPassword: ''
		},

		validate: {
			password: (value) => {
				if (!value) return 'Password is required';
				if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value))
					return 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, and one digit.';
				return null;
			},
			confirmPassword: (value, allValues) => {
				if (!value) return 'Confirm Password is required';
				return value !== allValues.password ? 'Passwords do not match' : null;
			}
		}
	});

	/* API call for reset password */
	const handleSubmit = (values: FormValues) => {
		setLoading(true);
		const params = {
			password: values.password
		};
		httpService
			.post(`${API_CONFIG.path.resetPassword}/${token}`, params)
			.then((res) => {
				setLoading(false);
				navigate('/login');
				notifications.show({
					message: res.message,
					color: 'green'
				});
			})
			.catch((error: AxiosError) => {
				setLoading(false);
				notifications.show({
					message: error.response?.data['message'] || error.message,
					color: 'red'
				});
				if (error.response.status === 401) {
					navigate('/token-expired');
				}
			});
	};

	return (
		<Box pos={'relative'}>
			{loading && (
				<LoadingOverlay
					loaderProps={{
						size: 'xl'
					}}
					visible={loading}
					overlayBlur={2}
				/>
			)}
			<div>
				<Text size={27} ta='center' fw={700}>
					Reset Password
				</Text>

				<form onSubmit={form.onSubmit(handleSubmit)} style={{ marginTop: '10px' }}>
					{RESET_PASSWORD_FIELDS.map(({ label, name, placeholder }, index) => {
						return (
							<PasswordInput
								withAsterisk
								placeholder={placeholder}
								{...form.getInputProps(name)}
								style={{ marginTop: '20px' }}
								{...others}
								radius='md'
								size='md'
								key={index}
							/>
						);
					})}

					<Space h='xl' />
					<Button
						fullWidth
						radius='md'
						size='md'
						sx={{
							fontSize: '14px'
						}}
						variant='gradient'
						type='submit'
					>
						Reset
					</Button>
				</form>
			</div>
		</Box>
	);
};

export default ResetPassword;
