import { FC, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Group, Loader } from '@mantine/core';

import Login from 'features/login/component/loginForm';

import { State } from 'shared/interface';
import Layout from 'shared/hoc/layout/container/layout';
import {
	Dashboard,
	LeaveList,
	Timesheet,
	User,
	ViewUser,
	HolidayList,
	Request,
	Projects,
	Report,
	Calendar,
	UpcomingLeave,
	WfhList
} from 'shared/hoc/asyncComponents';

import ForgotPassword from 'features/forgot-password/container/forgotPasswordForm';
import ResetPassword from 'features/reset-password/component/resetPassword';
import PublicLayout from 'features/login/component/publicLayout';
import TokenExpired from 'features/reset-password/component/tokenExpired';
import './App.css';

const App: FC = () => {
	const isLoggedIn = useSelector((state: State) => state.auth.isLoggedIn);

	if (isLoggedIn) {
		return (
			<Layout>
				<Suspense
					fallback={
						<Group w='100%' h='500px' position='center' align='center'>
							<Loader size='xl' />
						</Group>
					}
				>
					<Routes>
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/users' element={<User />} />
						<Route path='/user/view/:id' element={<ViewUser />} />
						<Route path='/timesheet' element={<Timesheet />} />
						<Route path='/leave' element={<LeaveList />} />
						<Route path='/wfh' element={<WfhList />} />
						<Route path='/holiday' element={<HolidayList />} />
						<Route path='/request' element={<Request />} />
						<Route path='/projects' element={<Projects />} />
						<Route path='/calendar' element={<Calendar />} />
						<Route path='/report' element={<Report />} />
						<Route path='/upcoming' element={<UpcomingLeave />} />
						<Route path='*' element={<Navigate replace to='/dashboard' />} />
					</Routes>
				</Suspense>
			</Layout>
		);
	} else {
		return (
			<PublicLayout>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/reset-password/:token' element={<ResetPassword />} />
					<Route path='/token-expired' element={<TokenExpired />} />

					<Route path='*' element={<Navigate replace to='/login' />} />
				</Routes>
			</PublicLayout>
		);
	}
};

export default App;
