const RESET_PASSWORD_FIELDS = [
	{
		label: 'Password',
		name: 'password',
		placeholder: 'Password'
	},
	{
		label: 'Confirm Password',
		name: 'confirmPassword',
		placeholder: 'Confirm password'
	}
];

export default RESET_PASSWORD_FIELDS;
