import {
	IconBeach,
	IconCalendarEvent,
	IconCalendarX,
	IconComponents,
	IconReport,
	IconReportSearch,
	IconSend,
	IconDeviceLaptop
} from '@tabler/icons-react';
import { IconDashboard, IconUsers, IconCalendarTime } from '@tabler/icons-react';

/**
 * Side navigation bar routes
 * Default mantine colors: 'dark' | 'gray' | 'red' | 'pink' | 'grape' | 'violet' | 'indigo' | 'blue' | 'cyan' | 'green' | 'lime' | 'yellow' | 'orange' | 'teal'
 */
export const ROUTES = () => [
	{
		icon: <IconDashboard size='1.1rem' />,
		label: 'Dashboard',
		link: '/dashboard',
		color: 'blue'
	},
	{
		icon: <IconUsers size='1.1rem' />,
		label: 'Users',
		color: 'teal',
		link: '/users'
	},
	{
		icon: <IconCalendarTime size='1.1rem' />,
		label: 'Time Sheet',
		color: 'indigo',
		link: '/timesheet'
	},
	{
		icon: <IconCalendarX size='1.1rem' />,
		label: 'Leaves',
		color: 'orange',
		link: '/leave'
	},
	{
		icon: <IconDeviceLaptop size='1.1rem' />,
		label: 'WFH',
		color: 'indigo',
		link: '/wfh'
	},
	{
		icon: <IconBeach size='1.1rem' />,
		label: 'Holidays',
		color: 'cyan',
		link: '/holiday'
	},
	{
		icon: <IconSend size='1.1rem' />,
		label: 'Requests',
		color: 'violet',
		link: '/request'
	},
	{
		icon: <IconReport size='1.1rem' />,
		label: 'Projects',
		color: 'blue',
		link: '/projects'
	},
	{
		icon: <IconCalendarEvent size='1.1rem' />,
		label: 'Calendar',
		color: 'teal',
		link: '/calendar'
	},
	{
		icon: <IconReportSearch size='1.1rem' />,
		label: 'Report',
		color: 'pink',
		link: '/report'
	},
	{
		icon: <IconComponents size='1.1rem' />,
		label: 'Upcoming',
		color: 'lime',
		link: '/upcoming'
	}
];
