import React from 'react';
import { Box, Flex, Text, rem } from '@mantine/core';
import { Scaletech } from 'shared/icons/icons';

import '../styles/layout.scss';
export function Brand() {
	return (
		<Box
			sx={(theme) => ({
				paddingLeft: theme.spacing.xs,
				paddingRight: theme.spacing.xs,
				paddingBottom: theme.spacing.sm,
				paddingTop: theme.spacing.sm,
				borderBottom: `${rem(1)} solid ${
					theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
				}`
			})}
		>
			<Flex justify='flex-start' align='center' pl={15} pr={15}>
				<div className='img-wrapper'>
					<Scaletech className={'org-icon'} />
				</div>

				<Text ta='center' fw={700} variant='gradient' fz={'md'} tt='uppercase'>
					Scaletech
				</Text>
			</Flex>
		</Box>
	);
}
