import * as actionTypes from 'store/action-types';
import { AuthState, Action } from 'shared/interface';
import authService from 'shared/services/auth.service';

const initialState: AuthState = {
	isLoggedIn: authService.checkLogin(),
	...authService.getAuthData(),
};

const reducer = (state: AuthState = initialState, action: Action): AuthState => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			};
		case actionTypes.AUTH_LOGOUT_SUCCESS:
			authService.removeAuthData();
			return {
				...state,
				isLoggedIn: false
			};	
		
		default:
			return state;
	}
};
export default reducer;
