import { Action, IStatus } from 'shared/interface/state';
import * as actionTypes from 'store/action-types';

const initialState: IStatus = {
	status: ''
};

const getStatus = (state: IStatus = initialState, action: Action) => {
	switch (action.type) {
		case actionTypes.SET_STATUS:
			return {
				...state,
				status: action.payload
			};

		default:
			return state;
	}
};

export default getStatus;
