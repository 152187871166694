import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { QueryParameters } from 'shared/interface';

export const API_CONFIG = {
	baseUrl: `${process.env.REACT_APP_BASE_URL}`,
	path: {
		login: 'login',
		forgotPassword: 'password/forgot',
		resetPassword: 'password/reset',
		logout: 'logout',
		user: 'user/list',
		userExcel: 'user/excel',
		getUser: 'user/get',
		updateUser: 'user/update',
		userDropdown: 'user/dropdown',
		timesheet: 'timesheet/list',
		leaveList: 'leave/list',
		wfhList: 'wfh/list',
		userTimeSheet: 'timesheet',
		holiday: 'holiday',
		leaveExcel: 'leave/excel',
		wfhExcel: 'wfh/excel',
		leaveCancel: 'request/leave/cancel',
		wfhCancel: 'request/wfh/cancel',
		excelTimeSheet: 'timesheet/excel',
		request: 'request',
		requestAction: 'request/leave/action',
		missingDayAction: 'request/missingday/action',
		changeTimeSheetAction: 'request/changetime/action',
		project: 'project',
		wfh: 'request/wfh',
		redirect: 'user/redirect',
		calendar: 'calendar/report',
		superExcel: 'report/superexcel',
		completeReport: 'report/completereport',
		dailyReport: 'report/dailyreport',
		allUserDropdown: 'user/all',
		timesheetReport: 'report/timesheet',
		upcomingleave: 'leave/upcoming/list',
		absentList: 'dashboard/absentlist',
		lateCheckin: 'dashboard/latecheckin',
		todayLeave: 'dashboard/todayleave',
		leaveBalance: 'leave/grantedleave',
		addLeave: 'leave/create/grantedleave',
		deleteLeaveBalance: 'leave/delete/grantedleave',
		updateLeaveBalance: 'leave/update/grantedleave'
	}
};

export const getUrl = (url: string, params: QueryParameters = {}): string => {
	const baseUrl = API_CONFIG.baseUrl;
	if (!url.includes('https')) {
		let urlString = `${baseUrl}/${url}`;
		if (params && !isEmpty(params)) {
			urlString += `?${queryString.stringify(params)}`;
		}
		return urlString;
	}
	return url;
};
